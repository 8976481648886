import { sanitizeQueryString } from '../../helpers/queryHelper';

export default class PluginApi {
  constructor() {
    this.pluginApiHost = process.env.DISCOVERY_API_HOST;
  }

  async getPublisherSettings(publisher_id) {
    const response = await fetch(
      `${this.pluginApiHost}/publisher/${publisher_id}/publisher_settings`
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }
  async getEventById(eventId, options) {
    const response = await fetch(
      `${this.pluginApiHost}/events/${eventId}?` + sanitizeQueryString(options)
    );

    if (response.ok) {
      return await response.json();
    } else if ([404,410].includes(response.status)) {
      return this.parseErrorResponse(response);
    } else {
      throw new Error('Request failed with status ' + response.status);
    }
  }

  async getHomePageEvents(publisherId, options = {}) {
    const response = await fetch(
      `${this.pluginApiHost}/publisher/${publisherId}/home_page_events?` +
      sanitizeQueryString(options)
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }

  async getEvents(filters) {
    const response = await fetch(`${this.pluginApiHost}/events?` + sanitizeQueryString(filters));

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return response.json();
  }

  async getFeaturedEvents(filters) {
    return this.getEvents({ ...filters, isFeatured: true });
  }

  async search(filters) {
    const response = await fetch(`${this.pluginApiHost}/events/search?` +
      sanitizeQueryString(filters));

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return response.json();
  }

  async getWidgetEvents(publisherId, filters) {
    const response = await fetch(
      `${this.pluginApiHost}/publisher/${publisherId}/widget_events?` +
      sanitizeQueryString(filters)
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }

  async getRecommendedEvents(filters) {
    return this.getEvents(filters);
  }

  async parseErrorResponse(response) {
    try {
      return await response.json();
    } catch (jsonError) {
      throw new Error('Failed to parse error response as JSON:', jsonError);
    }
  }
}
